@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Poppins", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font-poppins{
  font-family: "Poppins", serif !important;
}
.ls-wide{
  letter-spacing: 0.02rem;
}
h1, h2, h3, h4, h5, h6{
  font-family: "Inter", sans-serif !important;
}
/*comon css*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:focus,
:focus-visible {
  box-shadow: none !important;
  outline: none !important;
}



.heightautoscroll {
  height: calc(100vh - 83px) !important;
  overflow-y: auto;
  background:linear-gradient(180deg, #0053CD 0%, rgba(20, 136, 255, 0.5) 100%);
  background-size: cover;
}

.h-36 {
  height: 36px !important;
}

.h-40 {
  height: 40px;
}

.h-44 {
  height: 44px !important;
}

.h-48 {
  height: 48px;
}


.btn-primary {
  background: #0053cd !important;
  color: #fff !important;
  font-weight: 600 !important;
  width: 100%;
  border-radius: 6px !important;
}

/* .OpenContarct .container {
  max-width: 1760px;
} */

.SipBtns {
  display: flex;
  align-items: center;
}

.btn-orange {
  background: #e88715 !important;
  color: #fff !important;
  padding: 10px 18px;
}

.modal-header .modal-title.h4 {
  color: #fff;
  font-size: 16px;
}

.Contracts {
  min-height: 120px;
  position: relative;
}

.spinner-grow.text-primary {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  top: 50%;
}

.btn-white {
  background-color: #fff !important;
  color: #0053cd;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 22px;
  text-align: center;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

/**/
.border-yellow {
  border: 1px solid #ffbc01;
}

.bg-yellow {
  background: #ffbc01 !important;
}

.btn-hover-effect i {
  width: 45px;
  border-radius: 8px;
}

.btn-hover-effect i img {
  width: 15px;

}

.btn-hover-effect .backbutton i img {
  transform: rotate(-180deg);
}

.btn-hover-effect .backbutton:hover:before {
  left: 0;
  transition: all ease-in-out 0.5s;
  right: auto;
}

.btn-hover-effect button.arrowblue:hover:before,
.btn-hover-effect button.arrowyellow:hover:before,
.btn-hover-effect h6.arrowyellow:hover:before,
.btn-hover-effect a.arrowyellow:hover:before,
.btn-hover-effect h6.arrowblue:hover:before,
.btn-hover-effect a.arrowblue:hover:before {
  width: 100%;
  right: 0;
  transition: all ease-in-out 0.5s;
}

.btn-hover-effect button.arrowyellow:hover,
.btn-hover-effect h6.arrowyellow:hover,
.btn-hover-effect a.arrowyellow:hover {
  color: #000;
}

.btn-hover-effect button:hover,
.btn-hover-effect h6:hover,
.btn-hover-effect a:hover {
  color: #fff;
}

.btn-hover-effect {
  display: block;
  /* border-radius: 8px;
  border: 0.5px solid #0053CD33; */
}
.saveasdraft.btn-hover-effect h6{
  line-height: 27px !important;
}
.btn-hover-effect button.arrowyellow,
.btn-hover-effect button.arrowblue,
.btn-hover-effect h6.arrowyellow,
.btn-hover-effect a.arrowyellow,
.btn-hover-effect h6.arrowblue,
.btn-hover-effect a.arrowblue {
  z-index: 1;
  transition: all ease-in-out 0.5s;
  overflow: hidden;
  margin-bottom: 0;
  height: 48px;
  line-height: 33px;
  background: #EAEAEA;
  border-radius: 8px;
}

.btn-hover-effect button.arrowyellow.backbutton:before,
.btn-hover-effect h6.arrowyellow.backbutton:before {
  left: 0;
  right: auto;
}

.btn-hover-effect button.arrowyellow:before,
.btn-hover-effect h6.arrowyellow:before,
.btn-hover-effect a.arrowyellow:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 0;
  background-color: #ffbc01;
  height: 100%;
  border-radius: 4px;
  z-index: -1;
  transition: all ease-in-out 0.5s;
}

.btn-hover-effect button.arrowblue:before,
.btn-hover-effect h6.arrowblue:before,
.btn-hover-effect a.arrowblue:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 0;
  background-color: #0053cd;
  height: 100%;
  border-radius: 4px;
  z-index: -1;
  transition: all ease-in-out 0.5s;
}

/*text deceleration*/
.font-poppins {
  font-family: "Poppins", sans-serif !important;
}

.font-inter {
  font-family: "Inter", sans-serif !important;
}

.text-8 {
  font-size: 8px !important;
}
.text-9 {
  font-size: 9px !important;
}
.text-10 {
  font-size: 10px !important;
}

.text-11 {
  font-size: 11px !important;
}

.text-12 {
  font-size: 12px !important;
}

.text-13 {
  font-size: 13px !important;
}

.text-14 {
  font-size: 14px !important;
  line-height: 1.4;
}

.text-15 {
  font-size: 15px !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-18 {
  font-size: 18px !important;
}

.text-20 {
  font-size: 20px !important;
}

.text-22 {
  font-size: 22px !important;
}

.text-24 {
  font-size: 24px !important;
}

.text-28 {
  font-size: 28px !important;
}

.text-30 {
  font-size: 30px !important;
}

.text-32 {
  font-size: 32px !important;
}
.text-34 {
  font-size: 34px !important;
}
.text-36 {
  font-size: 36px !important;
}
.text-38 {
  font-size: 38px !important;
}

.text-53 {
  font-size: 53px !important;
}

.text-64 {
  font-size: 64px !important;
}

.text-grey {
  color: #9199a3 !important;
}

.bg-grey {
  background: #F0F0F0 !important;

}

.text-blue {
  color: #3375D7 !important;
}

.border-grey {
  border: 1px solid rgba(140, 140, 140, 0.416) !important;
}

.bgprimary,
.bg-blue {
  background-color: #3375D7 !important;
}

.border-blue {
  border: 1px solid #0053cd !important;
}

.border-green {
  border: 1px solid #188918 !important;
}

.text-primary {
  color: #0053cd !important;
}

.bg-lightgrey {
  background: #efefef;
}

.text-orange {
  color: #e67136 !important;
}
.text-lightorange {
  color: #FF990B !important;
}

.border-lightorange {
  border: 1px solid #FFF4E3 !important;
}

.bg-lightblue {
  background: rgba(0, 83, 205, 0.102) !important;
}
.tab-content.bg-light{
  background-color: #f7f7f7 !important;
}
.bg-lightorange {
  background: #FFF4E3 !important;
}

.border-lightblue{
  border:1px solid rgba(79, 70, 199, 0.102) !important;
}

.text-navyblue {
  color: #4f46c7 !important;
}

.bg-lightgreen {
  background: rgba(185, 235, 190, 0.481) !important;
  color: #188918 !important;
}
.border-lightgreen {
  background: 1px solid rgba(185, 235, 190, 0.481) !important;
}

.bg-lightred {
  background: #FFD8D8 !important;
  border: 1px solid #FFD8D8;
}

.bg-lightorange {
  background: rgba(230, 113, 54, 0.051) !important;
  border: 1px solid rgba(230, 113, 54, 0.051);
}

.bg-lightwhiteblue {
  background: rgba(0, 82, 205, 0.48);

}

.bgbluegradient {
  background: linear-gradient(180deg, #0053cd 0%, rgba(20, 136, 255, 0.5) 100%);
}

.bggradient {
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%);
}
.connectionpaymenttab  .form-check-input[type=radio],
.customRadio  .form-check-input[type=radio] {
  	border: 2px solid #858585 !important;
  }
.form-check-input[type=radio] {
  appearance: none;
	-webkit-appearance: none;
	width: 20px;
	height: 20px;
	border: 2px solid #ffffff !important;
	border-radius: 50%;
	margin-right: 0px;
	background-color: transparent;
	position: relative;
	top: 0px;
}

.otpverification input[type="tel"] {
  border: 0;
}

.modal-dialog.ElibalModal {
  max-width: 908px;
}

.ElibalModal .modal-header button {
  position: absolute;
  right: 26px;
}

.ElibalModal .modal-header h5 {
  font-size: 32px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  margin: 0 auto;
}

.ElibalModal .modal-header {
  background: #d7e7fc;
  text-align: center;
}

.cmn-tbn {
  background: #0053cd;
  color: #fff;
  border: 0;
  padding: 14px 30px;
  border-radius: 7px;
}

.grn-tbn {
  background: #1ec20f;
  color: #fff;
  border: 0;
  padding: 14px 30px;
  border-radius: 7px;
}

.modal-dialog.Profile-modal {
  max-width: 635px;
}

.modal-header {
  border: 0 !important;
}

.btn.btn-white.needhelp {
  border: 1px solid #0053cd;
  color: #0053cd;
  background: #fff;
  padding: 14px 40px;
  border-radius: 10px;
}

.btn {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.wht-btn {
  border: 1px solid #d0d5dd;
  color: #000;
  background: #fff;
  padding: 14px 30px;
  border-radius: 7px;
}

/**/
.form-control {
  width: 100%;
  height: 48px;
  top: 40px;
  border-radius: 8px;
  border: 1px solid #eceef0;
  color: #999999;
}

.feeds input.form-control {
  height: 42px !important;
}

label {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #333333;
}

/*heading style*/

.newheadingstyle p {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.8);
}
/* .check{
  width: 500px !important;
} */
 .slick-current{
  width: none !important;
 }

.newheadingstyle h3 {
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  color: #000;
}

.newheadingstyle h3 span {
  background: #0053CD14;
  font-size: 11px;
  color: #0053CD !important;
  font-weight: 500;
  display: inline-block;
  border-radius: 4px;
  padding: 10px 16px;
}

.newheadingstyle h3 span img {
  margin-right: 3px;
}

/*form search*/
.form-search input {
  padding-left: 35px;
}

.form-search {
  position: relative;
}

.form-search span {
  position: absolute;
  left: 10px;
  transform: translateY(-50%);
  top: 50%;
}

/**/
button.slick-arrow.slick-disabled {
  display: none !important;
}

.slick-next {
  background: url(./assets/images/arrow_next.svg) no-repeat center !important;
  content: "";
  background-size: contain;
  width: 36px;
  height: 36px;
  z-index: 99;
}

.slick-next::before,
.slick-prev::before {
  /* background: ; */
  content: "";
}

.slick-next {
  right: -10px;
}

.slick-arrow.slick-prev.slick-disabled {
  display: none !important;
}

.slick-arrow.slick-prev {
  left: -10px;
  background: url(./assets/images/arrow_next.svg) no-repeat center;
  z-index: 9;
  width: 36px;
  height: 36px;
  top: 50%;
  transform: translateY(-50%) rotateY(180deg);
}

.slick-arrow.slick-prev.slick-disabled {
  display: none;
}

.slick-slider::before {
  /* background: url(./assets/images/white_shadow_large.svg) no-repeat right;
  position: absolute;
  content: "";
  right: 0;
  width: 20%;
  height: 100%;
  z-index: 1;
  background-size: contain;
  pointer-events: none; */
}

.slick-slider {
  position: relative;
}

/**/
.social_button ul span {
  font-size: 14px;
  color: #9499a1;
  margin-right: 7px;
}

.social_button ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.social_button li {
  border: 1px solid #d4d4d4;
  width: 29px;
  height: 29px;
  border-radius: 7px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 7px;
  color: #0053cd;
  cursor: pointer;
}

/**/
.filter_sort .dropdown button {
  background-color: transparent !important;
  padding: 0;
  color: #222b45 !important;
  font-weight: 500;
  height: auto;
  min-width: 120px;
}

/**/
.light-danger {
  background: rgba(230, 113, 54, 0.051) !important;
  border: 1px solid rgba(230, 113, 54, 0.051);
  color: #e67136 !important;
}

.bg-orange {
  background: #eab01b !important;
}

.bg-blue {
  background: #0053cd !important;
}

.border-blue {
  border-color: #0053cd !important;
}

button.cmbtn {
  background: #0053cd;
  font-size: 15px;
  color: #fff;
  padding: 12px 30px;
  border-radius: 3px;
  border: 0;
  margin-right: 19px;
}

button.cmbtntwo {
  background: #fff;
  font-size: 15px;
  color: #0053cd;
  padding: 12px 30px;
  border-radius: 3px;
  border: 0;
  margin-right: 19px;
  border: 1px solid #0053cd;
  min-width: 140px;
  height: 46px;
}

/**/
.slick-list .slick-slide {
  padding: 0 8px;
}

.slick-list .slick-slide:nth-of-type(:last-child) {
  padding-right: 0;
}

.share_connect_friends .slick-slide img {
  display: inline-block;
}

.share_connect_friends .slick-slide i img {
  height: 15px;
  width: 15px;
}

.share_connect_friends .slick-slide .banner_img figure img {
  height: 250px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 12px;
  width: 100%;
}

.share_connect_friends .slick-slide figure img {
  /* height: 45px; */
}

::-webkit-scrollbar {
  width: 7px;
  width: 7px;
  border-radius: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d4d4d4;
  border-radius: 20px;
}


/**/

.form-select {
  background: #f7f7f7 !important;
  border: 0;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: table;
  padding: 0 22px;
  height: 48px;
  font-size: 14px;
  border-radius: 50px !important;
  color: #095ad0 !important;
  outline: none;
  appearance: none;
  text-align: left;
  font-weight: 400 !important;
}

/**/
.modal-dialog {
  max-width: 873px;
}

.modal-header {
  background: #0053cd;
}

.modal-header h5 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

button.btn-close {
  color: #fff;
  opacity: 1;
  border-radius: 100px;
  padding: 3px 7px 10px !important;
  filter: invert(1);
}

/**/
.clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.clamp1 {
  -webkit-line-clamp: 1;
}
.clamp2 {
  -webkit-line-clamp: 2;
}

.InterviewModal .modal-header h5 {
  font-size: 22px;
  color: #fff;
}

.InterviewModal .modal-header {
  background: #0053cd;
  padding: 10px 0;
}

.ck .ck-editor__main {
  border-radius: 0 0 12px 12px !important;
  overflow: hidden;
  border: 1px solid #ccc;
}

.ck .ck-editor__top {
  border-radius: 12px 12px 0 0 !important;
  border: 1px solid #ccc !important;
  overflow: hidden;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border: 0 !important;
}

.ck .ck-sticky-panel {
  /* border-radius: 12px 12px 0 0 !important; */
  /* overflow: hidden; */
  border: 0 !important;
}

.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
  border: 0 !important;
}

.ck.ck-editor {
  border-radius: 12px;
  border: 1px solid #fff;
  padding: 15px;
}

.ck-editor__editable_inline,
.custom-editor .ck-editor__editable_inline {
  min-height: 300px;
  /* Adjust the height as needed */
}

.modal-dialog.addModal {
  max-width: 870px;
}

.addModalPro .formGroup label {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 7px;
}

.addModalPro .formGroup input {
  height: 52px;
  width: 100%;
  border: 1px solid #0053cd33;
  border-radius: 50px;
  padding: 0 17px;
  font-size: 15px;
}

.addModalPro {
  padding: 16px 18px;
}

.addModalPro .formGroup {
  position: relative;
}

.addModalPro .formGroup span {
  /* position: absolute; */
  right: 20px;
  top: 41px;
  font-size: 18px;
  font-weight: 600;
  color: #0053cd;
}

.addModalPro ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 19px 0 36px;
}

.addModalPro ul li {
  margin-right: 10px;
}

.addModalPro ul li span {
  border: 1px solid #31313133;
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 19px;
  display: inline-block;
  margin: 5px 0;
  border-radius: 100px;
}

.addModalPro ul li.active span {
  background: #0053cd;
  color: #fff;
  border-color: #0053cd;
  font-weight: 600;
}

.addModalPro button {
  font-size: 14px;
  font-weight: 500;
  background: #0053cd;
  border: 0;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
}

.modal-dialog .addModalPro .formGroup {
  margin-bottom: 15px;
}

.modal-dialog .addModalPro .formGroup input {
  height: 48px;
  background: #f7f7f7;
  border: 0;
  border-radius: 10px;
}

.modal-dialog .addModalPro .formGroup button {
  /* margin-top: 20px; */
}

.modal-dialog .addModalPro .formGroup textarea {
  height: 142px;
  width: 100%;
  border: 0;
  border-radius: 6px;
  padding: 10px 17px;
  font-size: 15px;
  background: #f7f7f7;
}

/**/

figure {
  margin-bottom: 0;
}

.contractpages {
  /* height: calc(100% - 82px); */
}

.contractradio.bgbluegradient .form-check {
  width: 100%;
}

.select__menu {
  z-index: 99 !important;
  display: relative !important;
}

.select__value-container {
  margin: -10px 0;
}

.topTipHover {
  display: flex;
  align-items: center;
}

.editprofilepage .topTipHover,
.editprofilepage .topTipHover>div {
  /* width: 100%; */
}
.heightautoscroll .topTipHover,
.heightautoscroll .topTipHover>div{
width: 100%;
}
.topTipHover,
.topTipHover>div {
  width: 90%;
  /* display: flex;
  align-items: center;  */
  /* z-index: 1; */
  flex-wrap: wrap;
  position: relative;
}

.form-switch .form-check-input:checked{
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
}

.connectionpaymenttab .form-check-input[type="checkbox"]:checked,
.paymentmenthods .form-check-input[type="checkbox"]:checked,
.selectpaymentterms .form-check-input[type="checkbox"]:checked{
  filter: invert(1);
}

.connectionpaymenttab .form-check-input[type="checkbox"],
.paymentmenthods .form-check-input[type="checkbox"],
.selectpaymentterms .form-check-input[type="checkbox"]{
  filter: none;
}


.paymentmenthods .form-check-input:disabled{
  filter: invert(1);
  opacity: 0.3;
}
.paymentmenthods .form-check-input,
.selectpaymentterms .form-check-input,
.Reviewcontarctdetailform .form-check-input{
 filter: invert(1);
}
.form-check-input:checked{
  background-color: transparent !important;
  border-color: #ffffff !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

/**/
div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
  background-color: #0053CD !important;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  font-size: 1.5em !important;
}

/**/
.objectfitcover{
  object-fit: cover;
}
.btn {
  /* display: inline-flex !important;
  align-items: center; */
  /* justify-content: center; */
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide>div>.col {
  height: calc(100% - 7px);
}

.slick-slide>div {
  height: 100%;
}

/**/


@media (max-width: 1200px) {
  .btn-hover-effect i {
    width: 48px;
  }

  .text-11 {
    font-size: 10px !important;
  }

  .text-12 {
    font-size: 11px !important;
  }

  .text-13 {
    font-size: 12px !important;
  }

  .text-14 {
    font-size: 13px !important;
  }

  .text-15 {
    font-size: 14px !important;
  }

  .text-16 {
    font-size: 15px !important;
  }

  .text-18 {
    font-size: 17px !important;
  }

  .text-20 {
    font-size: 19px !important;
  }

  .text-22 {
    font-size: 20px !important;
  }

  .text-24 {
    font-size: 22px !important;
  }

  .text-28 {
    font-size: 24px !important;
  }

  .text-34 {
    font-size: 32px !important;
  }


  .text-38 {
    font-size: 38px !important;
  }
  .text-48 {
    font-size: 48px !important;
  }

  .text-53 {
    font-size: 46px !important;
  }

  .text-64 {
    font-size: 58px !important;
  }
}

@media (max-width: 991.99px) {
  .heightautoscroll {
    height: calc(100vh - 120px) !important;
  }
}

@media (min-width: 767.99px) {
  .Search-opportunities-border {
    border-left: 1px solid #f4f4f4;
    border-right: 1px solid #f4f4f4;
  }
}

@media (max-width: 991px) {

  .btn-hover-effect i {
    width: 40px;
  }

  .text-11 {
    font-size: 8px !important;
  }

  .text-12 {
    font-size: 10px !important;
  }

  .text-13 {
    font-size: 11px !important;
  }

  .text-14 {
    font-size: 12px !important;
  }

  .text-15 {
    font-size: 12px !important;
  }

  .text-16 {
    font-size: 14px !important;
  }

  .text-18 {
    font-size: 16px !important;
  }

  .text-20 {
    font-size: 16px !important;
  }

  .text-22 {
    font-size: 18px !important;
  }

  .text-24 {
    font-size: 20px !important;
  }

  .text-34 {
    font-size: 30px !important;
  }

  .text-28 {
    font-size: 20px !important;
  }

  .text-38 {
    font-size: 28px !important;
  }

  .text-48{
    font-size: 38px !important;
  }

  .text-53 {
    font-size: 38px !important;
  }

  .text-64 {
    font-size: 42px !important;
  }

  .newheadingstyle h3 {
    font-size: 18px !important;
  }

}

@media (max-width: 767px) {
  .contractradio.heightautoscroll label.form-check-label {
    padding: 12px;
  }

  .topTipHover>div i {
    /* position: absolute;
    right: -6px;
    top: -3px; */
  }

  .text-24 {
    font-size: 18px !important;
  }

  .text-34 {
    font-size: 26px !important;
  }

  .topTipHover>div h4 {
    /* padding-right: 20px; */
  }

  .py-4.bgbluegradient {
    padding-bottom: 100px !important;
  }

  .text-28 {
    font-size: 18px !important;
  }

  .text-38 {
    font-size: 22px !important;
  }

  .text-53 {
    font-size: 28px !important;
  }

  .text-64 {
    font-size: 32px !important;
  }

  /* .topTipHover i:hover + .cuatomeTop */
  .Search-opportunities-border {
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    padding: 25px 0;
    margin-bottom: 25px;
  }

  .btn-hover-effect i {
    width: 38px !important;
  }

  .modal {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .VideoModalSuccess .modal-content {
    background-color: #dbe3ec;
    padding: 37px;
  }

  .VideoModalSuccess .modal-header {
    background-color: transparent;
  }

  .VideoModalSuccess .modal-header button {
    color: #000;
    filter: unset;
  }

  .VideoModalSuccess .modal-content .modal-body>div {
    text-align: center;
  }

  .VideoModalSuccess .modal-content .modal-body>div h4 {
    font-size: 27px;
    font-weight: 700;
    margin: 16px 0;
  }

  .VideoModalSuccess .modal-content .modal-body>div p {
    font-size: 14px;
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .text-14 {
    font-size: 12px !important;
  }

  .text-16 {
    font-size: 12px !important;
  }

  .text-18 {
    font-size: 14px !important;
  }

  .text-34 {
    font-size: 20px !important;
  }

  .newheadingstyle h3 {
    font-size: 15px !important;
  }

  .btn.btn-primary.text-14,
  .newheadingstyle p {
    font-size: 10px !important;
  }
  .navtabing button{
    padding-left: 5px;
    padding-right: 5px;
  }
}

.payment-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f6f9;
}

.payment-success-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px 40px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
}

.payment-success-title {
  font-size: 24px;
  color: #0053CD;
  margin-bottom: 10px;
}

.payment-cancel-title {
  font-size: 24px;
  color: #da473d;
  margin-bottom: 10px;
}

.payment-success-message {
  font-size: 16px;
  color: #555555;
  margin-bottom: 20px;
}

.payment-success-button {
  background-color: #0053CD;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.payment-success-button:hover {
  background-color: #45a049;
}

.privacy-policy-container {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
}

.privacy-policy-container h1 {
  font-size: 32px !important;
}

.privacy-policy-container h2 {
  font-size: 26px !important;
}

.privacy-policy-container h3 {
  font-size: 22px !important;
}

.privacy-policy-container p {
  font-size: 14px !important;
}

.privacy-policy-container h1,
.privacy-policy-container h2,
.privacy-policy-container h3,
.privacy-policy-container h4,
.privacy-policy-container h5,
.privacy-policy-container h6 {
  color: #2c3e50;
  /* Heading color */
  margin-top: 20px;
  font-weight: 500;
}

.privacy-policy-container h3,
.privacy-policy-container h4,
.privacy-policy-container h5,
.privacy-policy-container h6 {
  font-size: 18px !important;
}

.privacy-policy-container p {
  margin-bottom: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

/**/
.dropdownfilterdata li .basic-multi-select {
  min-width: 180px;
  height: 38px !important;
}

.dropdownfilterdata li .basic-multi-select span {
  padding: 0;
  background: transparent;
  margin-right: 0;
  border: 0;
  height: auto;

}

.dropdownfilterdata li .basic-multi-select .select__control {
  padding: 0px 0px;
  background: #fff !important;
  height: 38px;
}

.dropdownfilterdata li .basic-multi-select {
  font-size: 14px;
  border: 1px solid #fff;
  background: #fff;
  padding: 0;
  border-radius: 100px;
  margin-right: 10px;
  height: 44px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}



/*ongoing contracts*/
.connectiontab .navtabing .nav-tabs{
  border: 0;
}
.connectiontab .navtabing {
  border-radius: 6px;

}
.connectiontab .navtabing button {
  border-radius: 6px !important;
}
.connectiontab .btn-outline-primary:hover {
  background: #4285f4 !important;
  color: #fff !important;
  border-color: #4285f4 !important;
}
.connectiontab .btn-outline-primary {
  border-color: rgba(0, 0, 0, 0.8) !important;
  color: rgba(0, 0, 0, 0.8) !important;;
}
.connectiontab .navtabing button.active .btn-outline-primary{
  color: #fff !important;
}
.connectiontab .navtabing button.active {
  background: #4285f4 !important;
  color: #fff !important;
  border-radius: 6px !important;
}
.navtabing .nav-tabs {
  /* background: rgba(0, 83, 205, 0.102); */
}
.navtabing button{
  border-bottom: 2px solid transparent !important;
}
.navtabing button:hover,
.navtabing button.active {
  color: #4285f4 !important;
  border-bottom: 2px solid #4285f4 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-top: 0 !important;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
}

.navtabing button {
  color: #000;
  border-radius: 0;
  background-color: transparent;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.42424243688583374px;
  text-align: left;
  text-transform: capitalize;

}

/**/
.photobanner img{
  height: 280px;
  object-fit: cover;
}

.PhoneInput {
  display: flex !important;
  align-items: center !important;
  height: 56px !important;
  border: 0 !important;
  background: #f7f7f7 !important;
  padding: 0 10px;
  border-radius: 10px;
}

.select__input{
  box-shadow: none !important;
}

.otp_icon{
  /* position: relative; */
}
.otp_icon i.active,
.otp_icon i:hover{ background: #0053CD;}
.otp_icon i.active img{
    position: relative;
}
.otp_icon i{
  background: rgba(0, 0, 0, 0.4);
  width: 35px;
  height: 35px;
  padding: 8px;
  border-radius: 50%;

}
.otp_icon .select-check span{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0;
  background: #0053cd;
  border: 2px solid #fff;
  border-radius: 50%;
  right: 0 !important;
  position: absolute;
  top: -25px;
}
.otp_icon i:nth-child(2).active + .select-check span{
  right: -43px !important;
}
.section{
  padding: 4rem 2rem;
}

.section .error{
  font-size: 150px;
  color: #0053CD;
 
}

.page{
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.back-home{
  display: inline-block;
  border: 2px solid #222;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 15px 15px -11px rgba(0,0,0, 0.4);
  background: #222;
  border-radius: 6px;
}
.back-home:hover{
  background: #222;
  color: #ddd;
}

/**/

.checkboxes__item {
  padding: 5px;
}

.checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox.style-c input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox.style-c input:checked ~ .checkbox__checkmark {
  background-color: #0053CD;
}
.checkbox.style-c input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}
.checkbox.style-c:hover input ~ .checkbox__checkmark {
  background-color: #eee;
}
.checkbox.style-c:hover input:checked ~ .checkbox__checkmark {
  background-color: #0053CD;
}
.checkbox.style-c .checkbox__checkmark {
  position: absolute;
  top: -15px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  transition: background-color 0.25s ease;
  border-radius: 4px;
}
.Reviewcontarctdetailform .checkbox.style-c .checkbox__checkmark,
.heightautoscroll .checkbox.style-c .checkbox__checkmark{
  top: 0;
}
.Reviewcontarctdetailform .checkbox.style-c .checkbox__checkmark{
  background-color: #fff;
}
.checkbox.style-c .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}

/**/
.table-striped tr:nth-child(2n+2) td{
  background-color: rgba(0, 82, 205, 0.08);
}
/**/
/**/
.requestinterview .dropdown-toggle::after,
.connectionpaymenttab .dropdown-toggle::after{
  right: 10px;
  color: #000;
}
.connectionpaymenttab .dropdown-toggle{
 border: 1px solid rgba(0, 0, 0, 0.2);
 background: transparent !important;
 color: #000 !important;
}
/**/
.react-datepicker-wrapper{
  position: relative;
}
/* .react-datepicker-wrapper::-webkit-calendar-picker-indicator{
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  background: url(./assets/images/calender_icon.svg) no-repeat right;
  background-size: contain;
  width: 20px;
  height: 20px;
  border-width: thin;
  position: absolute
  content: "";
  z-index: 9;

} */
/**/

/**/
@media (max-width: 767px) {
  .newheadingstyle h3 {
    font-size: 14px !important;
}
}
@media (min-width: 992px) {
  .modal-md .modal-dialog {
      --bs-modal-width: 650px;
  }
}

@media print {
  body {
    width: 1024px; /* Set fixed width */
  }
}