.skeletonloader {
    animation: shimmer 1.5s infinite linear;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
  }
  
  .skeletonlist {
    display: flex;
    flex-direction: column;
  }
  
  .skeletonlistitem {
    height: 60px;
    margin: 10px 0;
    background-color: #e0e0e0;
    border-radius: 4px;
    width: 100%;
  }
  
  .skeletondetails {
    margin-top: 20px;
  }
  
  .skeletonusername,
  .skeletonjobtitle,
  .skeletondescription {
    background-color: #e0e0e0;
    margin: 10px 0;
    height: 20px;
    border-radius: 4px;
    width: 80%;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }