.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.activeLink {
  color: #0053CD !important;
}

.LeftditPro h3 {
  font-size: 24px;
  font-weight: 500;
}

.LeftditPro ul {
  padding: 0;
  margin: 40px 0 0;
}

.LeftditPro ul li a {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  text-decoration: none;
  margin-bottom: 20px;
  display: table;
}

.RightditPro h3 {
  font-size: 24px;
  font-weight: 400;
  border-bottom: 1px solid #d7d8e2;
  padding: 16px 30px;
  margin-bottom: 0;
}

.ProfileEditHead img {
  /* width: 100%; */
}

.ProfileEditHead {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  /* padding: 15px 15px 0 15px; */
}

.sameRow {
  display: flex;
  align-items: center;
}

.spinner {
  margin-top: 30%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.editable {
  padding: 10px 15px;
  border-radius: 10px;
  height: 44px;
  /* border-color: green; */
  border: 1px solid #6698e1;
}

.ProfileEditHead span {
  position: relative;
  margin-right: 13px;
  width: 60px;
  height: 60px;
}

.ProfileEditHead span img {
  position: relative;
  pointer-events: none;
  z-index: 1;
  width: 61px;
  height: 61px;
  object-fit: cover;
  cursor: pointer;
}

.ProfileEditHead input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 0;
  cursor: pointer;
  padding: 20px;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.ProfileEditHead h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.ProfileEditHead h5 {
  font-size: 16px;
  font-weight: 400;
  margin: 4px 0 0;
}

.ProfileEdit h6 {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  margin: 15px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  position: relative;
  pointer-events: none;
  z-index: 1;
  width: 61px;
  height: 61px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 50%;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.middle img {
  width: 12px;
  filter: invert(1) brightness(20.5);
}

.ProfileEditHead:hover .image {
  opacity: 0.3;
}

.ProfileEditHead:hover .middle {
  opacity: 1;
}

.ProfileEdit ul li input {
  width: 100%;
  border-radius: 50px;
}

.ProfileEdit ul li {
  margin-bottom: 23px;
}

.ProfileEdit input:disabled {
  background-color: rgb(164 201 254 / 20%) !important;
  color: #000;
}

.ProfileEdit ul li span {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  width: 100%;
  display: table;
  margin: 0;
}

.ProfileEdit ul li label {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.deletAcount span {
  font-size: 18px;
  color: #f73116;
  font-weight: 500;
  margin-right: 25px;
}

.deletAcount {
  padding: 0 14px;
}

.ProfileEdit {
  border-bottom: 1px solid #d7d8e2;
  padding-bottom: 23px;
  /* padding-left: 30px;
    padding-right: 30px;
    padding-top: 23px; */
}

.AboutEdit p.text-10 {
  font-size: 10px !important;
}

.AboutEdit h4,
.SocialMediEdit h4,
.AboutEdit h4 {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}

.AboutEdit p {
  font-size: 14px;
  font-weight: 400;
}

.AboutEdit,
.SocialMediEdit,
.AboutEdit {
  border-bottom: 1px solid #d7d8e2;
  padding: 20px 5px;
}

.AboutEdit label {
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  margin-bottom: 5px;
}

.AboutEdit input[type="file"] {
  padding: 6px 16px;
}

.AboutEdit input {
  height: 44px;
  background: transparent;
  border: 1px solid #6698e1;
  border-radius: 50px;
  padding: 0 16px;
  width: 100%;
}

.SocialMediEdit ul {
  padding: 0;
  margin: 0;
}

.SocialMediEdit ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d7d8e2;
  padding: 14px 19px;
  border-radius: 6px;
  margin-bottom: 11px;
  font-size: 14px;
  color: #9199a3;
}

.SocialMediEdit ul li a {
  font-size: 16px;
  color: #0053cd;
  font-weight: 500;
  text-decoration: none;
}

.EditProfieOuterBord {
  background: #fff;
  border: 1px solid #d7d8e2;
}

.LeftditPro {
  border-right: 1px solid #d7d8e2;
  padding: 40px 50px;
  height: 100%;
}

.addSkills .formGroup input {
  height: 52px;
  border: 1px solid #f2f2f4;
}

.addSkills .formGroup {
  position: relative;
}

.addSkills .formGroup span {
  color: #0053cd;
  position: absolute;
  font-size: 16px;
  right: 16px;
  top: 13px;
  font-weight: 600;
}

ul.skilSet {
  padding: 0;
  margin: 12px 0 0;
  display: flex;
}

ul.skilSet li {
  margin-right: 13px;
}

ul.skilSet li span {
  background: #f5f7fd;
  font-size: 14px;
  font-weight: 400;
  padding: 7px 30px;
  display: flex;
  border-radius: 100px;
}

ul.experienceSet {
  padding: 0;
  margin: 0;
}

ul.experienceSet li span.expUi {
  width: 54px;
  height: 54px;
  border-radius: 100px;
  background: #ed8f03;
  display: flex;
  min-width: 54px;
  margin-right: 15px;
}

ul.experienceSet li h4 {
  font-size: 14px;
  margin: 0 0 2px;
  font-weight: 600;
}

ul.experienceSet li h5 {
  font-size: 13px;
  font-weight: 400;
}

ul.experienceSet li h6 {
  font-size: 13px;
  color: #181818;
}

ul.experienceSet li h6 span {
  color: #0053cd;
  font-weight: 600;
}

ul.experienceSet li p {
  font-size: 13px;
  line-height: 18px;
}

ul.experienceSet li {
  margin: 12px 0;
  display: flex;
}

.addPop p {
  font-size: 13px;
  color: #000;
  margin: 0;
  font-weight: 400;
  line-height: 2;
}

.addPop span {
  color: #0053cd;
  font-size: 16px;
  font-weight: 600;
}

.personalinfo span {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 15px;
}

.personalinfo label {
  color: #000;
  font-weight: 600 !important;
}

.uploadimagefile input {
  opacity: 0;
  z-index: 1;
}

.uploadimagefile {
  position: relative;
  z-index: 1;
}

.uploadimagefile + li i {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px;
  color: #f73116;
  background-color: #fff;
  border-radius: 50px;
}

.uploadimagefile + li img {
  width: 150px;
  height: 150px;
}

.uploadimagefile + li div {
  position: relative;
  width: 150px;
}

.uploadimage i {
  margin-right: 10px;
}

.uploadimage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #0053cd;
  border-radius: 10px;
  z-index: -1;
}
.pen-icon {
  width: 20px;  /* Fixed width */
  height: 20px; /* Fixed height */
  flex-shrink: 0; /* Prevent shrinking */
  object-fit: contain; /* Ensure proper scaling */
  display: block; /* Prevent unwanted inline scaling */
}

@media (max-width: 767px) {
  .LeftditPro {
    padding: 20px;
  }

  .LeftditPro ul li a {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .ProfileEdit ul li span {
    text-align: start;
  }
  .ProfileEditHead img {
    width: 50px;
    height: 50px;
  }
  .personalinfo span {
    font-size: 14px;
  }
}
